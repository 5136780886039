import { Alert, background, Box, Button, Checkbox, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import nftAbi from "../abis/packs.json"

import { ChevronDownIcon } from "@chakra-ui/icons";
const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Home() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = new ethers.Contract('0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1', nftAbi, signer)
    const beansiesContract = new ethers.Contract('0x822a2E2020bAcefFb106CFa4C13524EbEe54a7A8', nftAbi, signer)
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [nfts, setNfts] = useState([])
    const [amount, setAmount] = useState(1)
    const [price, setPrice] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [selected, setSelected] = useState([])
    const [approved, setApproved] = useState(false)

    const connect = async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }

    useEffect(() => {
        connect()
        constants.changeNetwork(43114)
        contract.price().then((price) => {
            setPrice(price)
        })
        setTotalPrice(price * amount)
        beansiesContract.isApprovedForAll(address,'0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1').then(
            (approved)=>{
                setApproved(approved)
            }
        )
    }, [address, amount, beansiesContract, connect, contract, price])

    const mint = async () => {
        contract.mint(amount, { value: totalPrice.toString() }).then((tx) => {
            tx.wait().then((receipt) => {
                console.log(receipt)
                toast({
                    title: "Transaction Successful",
                    description: "Your transaction has been confirmed",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            })
        }).catch((err) => {
            toast({
                title: "ERROR",
                description: err.reason||err.data.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        })
    }
    useMemo(() => {
        signer.getAddress().then((address) => {
            if(nfts.length) return
        fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses=0x822a2E2020bAcefFb106CFa4C13524EbEe54a7A8", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nfts) => {
            nfts.json().then(e => {
                setNfts(e.result)
                console.log(e.result)
            })
        })
    })
    }, [nfts.length, signer])


    const redeem = async () => {
        if(!approved){
        const tx1 = await beansiesContract.setApprovalForAll('0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1',true).catch(err=>toast({
            title: "ERROR",
            description: err.reason||err.data.message,
            status: "error",
            duration: 9000,
            isClosable: true,
        })).then(setApproved(true))
        await tx1.wait()
        }
        const tx2 = await contract.claim(selected).catch(err=>toast({
                title: "ERROR",
                description: err.reason||err.data.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            }))
        await tx2.wait()
        toast({
            title: "Transaction Successful",
            description: "Your transaction has been confirmed",
            status: "success",
            duration: 9000,
            isClosable: true,
        })
    }


    //console.log(nfts)

    return (

        <>
            <VStack align={'center'} textAlign='center'>
                <Heading>Mint/Redeem BeansiesEX</Heading>
                <Button onClick={() => connect()}>{address?shorten(address):'Connect Wallet'}</Button>
                <Box minHeight={'300px'}>
                <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                    {nfts?.map((nft, i) => {
                        const metadata = JSON.parse(nft.metadata)
                        return (
                            <>
                                <GridItem colSpan={1}>
                                <Box key={i} maxW={'300px'} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                    <Image src={metadata.image} />
                                    <Text textAlign='center'>{metadata.name}</Text>
                                    <Checkbox onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelected([...selected, nft.token_id])
                                        } else {
                                            setSelected(selected.filter((id) => id !== nft.token_id))
                                        }
                                    }} />
                                </Box>
                                </GridItem>
                            </>
                        )
                    })}

                </Grid>
                </Box>
                <Button onClick={() => redeem()}>Burn old beansies for BeansiesEX</Button>
                <Link href='/unpack'>
                        <Button>Go to Unpacking page</Button>
                </Link>
                <Link href='/build'>
                        <Button>Go to Building page</Button>
                </Link>
                <Image src={'https://gateway.ipfs.io/ipfs/bafybeigejj2rcxg57abm5gjwemqpvmzq7b3sg4wrjzzertiwbw3lg6jxc4/beansPack.png'} />
                <Input value={amount} onChange={e => setAmount(e.target.value)} type='number' placeholder='Amount' maxW={'300px'} max={10} min={1} />
                <Text>Max 10 packs per tx<br/>only 78 packs can be minted by public<br/>the remaining packs are claimable by beansies owners</Text>
                <Button onClick={() => mint()}>Mint {amount} packs for {ethers.utils.formatEther(totalPrice.toString())} AVAX</Button>
            </VStack>
        </>

    )

}