import { Alert, background, Badge, Box, Button, Checkbox, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { json, useParams } from "react-router-dom"
import abi from "../abis/beans.json"
import packAbi from "../abis/unpack.json"
import { ChevronDownIcon } from "@chakra-ui/icons";

const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Build() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = useMemo(()=> new ethers.Contract('0x8D6B54ED849a42e220997dBFc122447B7636b982', abi, signer), [signer])
    const traits = useMemo(() => new ethers.Contract('0x2646a687E19450a35b76639997ffC58Bc9b53078', packAbi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [nfts, setNfts] = useState([])
    const [traitsNFT, setTraitsNFT] = useState([])
    const [BeansiesEX, setBeansiesEX] = useState([])
    const [approved, setApproved] = useState(false)
    const [selectedtraits, settraits] = useState([0, 0, 0, 0, 0])
    const [background, setBackground] = useState()
    const [eyes, setEyes] = useState()
    const [mouth, setMouth] = useState()
    const [body, setBody] = useState()
    const [hat, setHat] = useState()
    const [metadata, setMetadata] = useState([])
    const [metadataEX, setMetadataEX] = useState([])
    const [state, setState] = useState(0)
    
    const connect = async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }



    useEffect(() => {
        connect()
        constants.changeNetwork(43114)
        signer.getAddress().then((address) => {
            traits.isApprovedForAll(address, '0x8D6B54ED849a42e220997dBFc122447B7636b982').then((e) => {
                setApproved(e)
            })
        })
    }, [address, traits, signer, state, connect])

    useMemo(() => {
        // signer.getAddress().then((address) => {
        //     if (nfts.length) return
        //     fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses%5B0%5D=0x2646a687E19450a35b76639997ffC58Bc9b53078&token_addresses%5B1%5D=0x8D6B54ED849a42e220997dBFc122447B7636b982", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nft) => {
        //         nft.json().then(e => {
        //             setNfts(e.result)
        //         })
        //     })
        // })

        traits.tokensOfOwner(address).then((e) => {
            setTraitsNFT(e)
            e.forEach((nft) => {
            fetch('https://pub-bb8e43e199654d2299b918dad62fbf79.r2.dev/traits/metadata/' + nft.toString() + '.json').then((w) => w.json().then((s) => {
                if(s.name)
                metadata[Number(nft.toString())] = s  
                metadata.filter(f=>f)
                setState(state + 1)
            }))
        })
    })

        contract.tokensOfOwner(address).then((e) => {
            setBeansiesEX(e)
            e.forEach((nft) => {
            fetch('https://pub-bb8e43e199654d2299b918dad62fbf79.r2.dev/metadata/' + nft + '.json').then((w) => w.json().then((s) => {
                metadataEX[Number(nft.toString())] = s    
                metadataEX.filter(f=>f)
                setState(state + 1)
            }))
        })
        })
    }, [address, contract, metadata, metadataEX, state, traits])

    const approve = async () => {
        try {
            const tx = await traits.setApprovalForAll('0x8D6B54ED849a42e220997dBFc122447B7636b982', true)
            await tx.wait()
            toast({
                title: "Approved",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            window.location.reload()
        } catch (err) {
            toast({
                title: "Error",
                description: err.reason || err.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const build = async () => {
        if (!approved) {
            await approve()
        }
        try {
            const tx = await contract.build(selectedtraits)
            await tx.wait()
            toast({
                title: "Built",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            toast({
                title: "Error",
                description: err.reason || err.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }
    return state>=2?(
        <>
            <Box p='5'>
                <VStack spacing={4} align="center">
                    <Heading>Build Your BeansiesEX</Heading>
                    <Button onClick={connect}>{address ? shorten(address) : 'Connect Wallet'}</Button>
                    <Box maxW="5xl" p='2' borderWidth="1px" borderRadius="lg" overflow="hidden" textAlign={'center'} alignContent='center'>
                        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            <VStack>
                                <Text>Background</Text>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        {background || 'Background'}
                                    </MenuButton>
                                    <MenuList>
                                        {traitsNFT?.map((e) => {
                                            const nft = Number(e.toString())
                                            if ((metadata[nft]?.attributes[1]!= undefined && metadata[nft]?.attributes[1].value == 'background')|| (metadata[nft]?.attributes[0].trait_type == 'background'))
                                                return (
                                                    <MenuItem onClick={() => {
                                                        settraits([nft, selectedtraits[1], selectedtraits[2], selectedtraits[3], selectedtraits[4]])
                                                        setBackground(
                                                            <MenuItem><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value}</MenuItem>
                                                        )
                                                    }}><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value} #{nft}</MenuItem>
                                                )
                                        })}
                                    </MenuList>
                                </Menu>
                            </VStack>
                            <VStack>
                            <Text>Body</Text>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        {body || 'Body'}
                                    </MenuButton>
                                    <MenuList>
                                    {traitsNFT?.map((e) => {
                                            const nft = Number(e.toString())
                                            if ((metadata[nft]?.attributes[1]!= undefined && metadata[nft]?.attributes[1].value == 'body')|| (metadata[nft]?.attributes[0].trait_type == 'body'))
                                                return (
                                                    <MenuItem onClick={() => {
                                                        settraits([selectedtraits[0], nft, selectedtraits[2], selectedtraits[3], selectedtraits[4]])
                                                        setBody(
                                                            <MenuItem><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value}</MenuItem>
                                                        )
                                                    }}><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value} #{nft}</MenuItem>
                                                )
                                        }
                                        )}
                                    </MenuList>
                                </Menu>

                            </VStack>
                            <VStack>
                                <Text>Mouth</Text>

                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        {mouth || 'Mouth'}
                                    </MenuButton>
                                    <MenuList>
                                    {traitsNFT?.map((e) => {
                                            const nft = Number(e.toString())
                                            if ((metadata[nft]?.attributes[1]!= undefined && metadata[nft]?.attributes[1].value == 'mouth')|| (metadata[nft]?.attributes[0].trait_type == 'mouth'))
                                                return (
                                                    <MenuItem onClick={() => {
                                                        settraits([selectedtraits[0], selectedtraits[1], nft, selectedtraits[3], selectedtraits[4]])
                                                        setMouth(
                                                            <MenuItem><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value}</MenuItem>
                                                        )
                                                    }}><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value} #{nft}</MenuItem>
                                                )
                                        }
                                        )}
                                    </MenuList>
                                </Menu>
                            </VStack>
                            <VStack>
                                <Text>Hat</Text>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        {hat || 'Hat'}
                                    </MenuButton>
                                    <MenuList>
                                    {traitsNFT?.map((e) => {
                                            const nft = Number(e.toString())
                                            if ((metadata[nft]?.attributes[1]!= undefined && metadata[nft]?.attributes[1].value == 'hat')|| (metadata[nft]?.attributes[0].trait_type == 'hat'))
                                                return (
                                                    <MenuItem onClick={() => {
                                                        settraits([selectedtraits[0], selectedtraits[1], selectedtraits[2], nft, selectedtraits[4]])
                                                        setHat(
                                                            <MenuItem><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value}</MenuItem>
                                                        )
                                                    }}><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value} #{nft}</MenuItem>
                                                )
                                        }
                                        )}
                                    </MenuList>
                                </Menu>
                            </VStack>
                            <VStack>
                            <Text>Eyes</Text>
                                <Menu>
                                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                        {eyes || 'Eyes'}
                                    </MenuButton>
                                    <MenuList>
                                    {traitsNFT?.map((e) => {
                                            const nft = Number(e.toString())
                                            if ((metadata[nft]?.attributes[1]!= undefined && metadata[nft]?.attributes[1].value == 'eye')|| (metadata[nft]?.attributes[0].trait_type == 'eye'))
                                                return (
                                                    <MenuItem onClick={() => {
                                                        settraits([selectedtraits[0], selectedtraits[1], selectedtraits[2], selectedtraits[3], nft])
                                                        setEyes(
                                                            <MenuItem><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value}</MenuItem>
                                                        )
                                                    }}><Image maxH={'50px'} src={metadata[nft]?.image}></Image>{metadata[nft]?.attributes[0].value} #{nft}</MenuItem>
                                                )
                                        }
                                        )}
                                    </MenuList>
                                </Menu>
                            </VStack>
                        </Grid>
                        <Box p="6">
                            <Box d="flex" alignItems="baseline">
                                <Badge borderRadius="full" px="2" colorScheme="teal">
                                    {metadata[selectedtraits[0]]?.attributes[0].value}
                                </Badge>
                                <Badge borderRadius="full" px="2" colorScheme="teal">
                                    {metadata[selectedtraits[1]]?.attributes[0].value}
                                </Badge>
                                <Badge borderRadius="full" px="2" colorScheme="teal">
                                    {metadata[selectedtraits[2]]?.attributes[0].value}
                                </Badge>
                                <Badge borderRadius="full" px="2" colorScheme="teal">

                                    {metadata[selectedtraits[3]]?.attributes[0].value}
                                </Badge>
                                <Badge borderRadius="full" px="2" colorScheme="teal">
                                    {metadata[selectedtraits[4]]?.attributes[0].value}
                                </Badge>
                            </Box>
                        </Box>
                        <VStack align={'center'}>
                            <Box bgImage={metadata[selectedtraits[0]]?.image.replace(' ', '%20')} bgSize="cover" bgPos="center" w="10%" h="10%">
                                <Box bgImage={metadata[selectedtraits[1]]?.image.replace(' ', '%20')} bgSize="cover" bgPos="center" w="100%" h="100px">
                                    <Box bgImage={metadata[selectedtraits[2]]?.image.replace(' ', '%20')} bgSize="cover" bgPos="center" w="100%" h="100px">
                                        <Box bgImage={metadata[selectedtraits[3]]?.image.replace(' ', '%20')} bgSize="cover" bgPos="center" w="100%" h="100px">
                                            <Box bgImage={metadata[selectedtraits[4]]?.image.replace(' ', '%20')} bgSize="cover" bgPos="center" w="100%" h="100px">
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Button onClick={() => build()} colorScheme="teal" variant="outline"> Build your BeansiesEX</Button>
                        </VStack>
                    </Box>
                    <Link href='/mint'>
                        <Button> Redeem/Mint Packs</Button>
                    </Link>
                    <Link href='/unpack'>
                        <Button> Unpack More Packs</Button>
                    </Link>
                    <Box p="6" maxW={'5xl'} borderWidth="1px" borderRadius="lg" overflow="hidden">
                        <Grid templateColumns="repeat(5, 1fr)" gap={6}>
                            {BeansiesEX?.map((nft) => {
                                const meta = metadataEX[nft]
                                    return (
                                        <GridItem colSpan={1}>
                                            <Box maxW={'sm'} borderWidth="1px" borderRadius="lg" overflow="hidden">
                                                <Image src={meta?.image} alt={meta?.name} />
                                                <Box p="6">
                                                    <Box d="flex" alignItems="baseline">
                                                        <Badge borderRadius="full" px="2" colorScheme="teal">
                                                            {meta?.attributes[0]?.value}
                                                        </Badge>
                                                        <Badge borderRadius="full" px="2" colorScheme="teal">
                                                            {meta?.attributes[1]?.value}
                                                        </Badge>
                                                        <Badge borderRadius="full" px="2" colorScheme="teal">
                                                            {meta?.attributes[2]?.value}
                                                        </Badge>
                                                        <Badge borderRadius="full" px="2" colorScheme="teal">
                                                            {meta?.attributes[3]?.value}
                                                        </Badge>
                                                        <Badge borderRadius="full" px="2" colorScheme="teal">
                                                            {meta?.attributes[4]?.value}
                                                        </Badge>
                                                    </Box>
                                                    <Box
                                                        mt="1"
                                                        fontWeight="semibold"
                                                        as="h4"
                                                        lineHeight="tight"
                                                        isTruncated
                                                    >
                                                        {meta?.name}
                                                    </Box>
                                                    <Box>
                                                        <Box as="span" color="gray.600" fontSize="sm">
                                                            {meta?.description}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </GridItem>

                                    )
                                }
                            )}
                        </Grid>
                    </Box>
                </VStack>
            </Box>
        </>

    ):(<>
    <Heading textAlign={'center'} verticalAlign='center' margin={'100'}>Loading <Spinner/><Button onClick={()=> connect()} alignSelf={'center'} textAlign={'center'}>Connect Wallet</Button></Heading>
    
    </>
    )
}
