import { Alert, background, Box, Button, Checkbox, Grid, GridItem, Heading, HStack, Image, Input, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spacer, Spinner, Stack, Text, useColorMode, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as constants from "../constants"
import { useParams } from "react-router-dom"
import abi from "../abis/unpack.json"
import packAbi from "../abis/packs.json"
import { ChevronDownIcon } from "@chakra-ui/icons";

const shorten = (add) => {
    return add.slice(0, 5) + "..." + add.slice(-4)
}



export default function Unpack() {

    const provider = constants.n43114.Provider
    const signer = constants.n43114.Signer
    const contract = new ethers.Contract('0x2646a687E19450a35b76639997ffC58Bc9b53078', abi, signer)
    const packs = useMemo(() => new ethers.Contract('0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1', packAbi, signer), [signer])
    const toast = useToast()
    const [address, setAddress] = useState("")
    const [nfts, setNfts] = useState([])
    const [approved, setApproved] = useState(false)
    const [selectedPacks, setPacks] = useState([])
    const [metadata, setMetadata] = useState([])
    const connect = async () => {
        try {
            provider.request({ method: 'eth_requestAccounts' });
        } catch (err) {
            console.log(err)
        }
        try {
            provider.enable()
        } catch (err) {
            console.log(err)
        }
        signer.getAddress().then((address) => {
            setAddress(address)
        })
    }


    useEffect(() => {
        connect()
        constants.changeNetwork(43114)
        signer.getAddress().then((address) => {
            packs.isApprovedForAll(address, '0x2646a687E19450a35b76639997ffC58Bc9b53078').then((e) => {
                setApproved(e)
                console.log(e)
            })
        })
    }, [address, connect, packs, signer])

    useMemo(async () => {
        signer.getAddress().then((address) => {
            if(nfts.length) return
            fetch("https://deep-index.moralis.io/api/v2/" + address + "/nft?chain=0xa86a&token_addresses%5B0%5D=0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1&token_addresses%5B1%5D=0x2646a687E19450a35b76639997ffC58Bc9b53078", { method: "GET", headers: { accept: 'application/json', 'X-API-Key': "rTO3ivS5kbrUYLADjiK07aprt07UIKh9lcdEy346azAzfSGi6zfYLBA4z9C1MuE4" } }).then((nft) => {
                nft.json().then(e => {
                    setNfts(e?.result)
                })
            })
        })
        nfts?.forEach((nft) => {
            fetch('https://pub-bb8e43e199654d2299b918dad62fbf79.r2.dev/traits/metadata/' + nft.token_id + '.json').then((e) => e.json().then((w) => {
                metadata[nft.token_id] = w
            }))
        })

    }, [metadata, nfts, signer])


    const approve = async () => {
        try {
            const tx = await packs.setApprovalForAll('0x2646a687E19450a35b76639997ffC58Bc9b53078', true)
            await tx.wait()
            toast({
                title: "Approved",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            window.location.reload()
        } catch (err) {
            toast({
                title: "Error",
                description: err.reason || err.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }

    const unpack = async () => {
        if (!approved) {
            await approve()
        }
        try {
            const tx = await contract.unpack(selectedPacks)
            await tx.wait()
            toast({
                title: "Unpacked",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
        } catch (err) {
            toast({
                title: "Error",
                description: err.reason || err.message,
                status: "error",
                duration: 9000,
                isClosable: true,
            })
        }
    }



    //console.log(nfts)

    return (
        <>
            <Box p='5'>
                <VStack spacing={4} align="center">
                    <Heading>Unpack BeansiesEX trait packs</Heading>
                    <Button onClick={connect}>{address ? shorten(address) : 'Connect Wallet'}</Button>
                    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" textAlign={'center'} alignContent='center'>
                        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                            {nfts?.map((nft) => {
                                if (nft.token_address === '0x95E85DaABA9154A10a9E9B5dB398b60220bbC6B1'.toLowerCase()) {
                                    return (
                                        <>
                                            <GridItem>
                                                <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden" textAlign={'center'} alignContent='center'>
                                                    <Image maxH={'150px'} src={JSON.parse(nft.metadata).image.replace('ipfs://', 'https://gateway.ipfs.io/ipfs/')} alt={JSON.parse(nft.metadata).name} />
                                                    <HStack>
                                                        <Checkbox onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setPacks([...selectedPacks, Number(nft.token_id)])
                                                            } else {
                                                                setPacks(selectedPacks.filter((e) => e !== Number(nft.token_id)))
                                                            }
                                                        }} />
                                                        <Text>{nft.token_id}</Text>
                                                    </HStack>
                                                </Box>
                                            </GridItem>
                                        </>
                                    )
                                }
                            })}
                        </Grid>
                    </Box>
                    <Button onClick={unpack}>Unpack</Button>
                    <Link href='/build'>
                        <Button>Go to Building page</Button>
                    </Link>
                    <Link href='/mint'>
                        <Button>Go to Minting page</Button>
                    </Link>
                </VStack>
            </Box>
        </>

    )

}